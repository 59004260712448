<template>
  <v-app class="espacios">
    <app-loader v-if="loader"></app-loader>

<!--    <app-header>  </app-header>-->
    <app-aside-bar :menu="menu" v-if="sideBar"></app-aside-bar>
    <v-main class="bgMinsal">
      <div v-if="token /* editado-w */">
        <router-view :key="$route.fullPath" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import jwtDecode from "jwt-decode"
import AppAsideBar from "@/components/AppAsideBarComponent.vue";
export default {
  name: "layout",
  components:{
    AppAsideBar
  },
  data() {
    return {
      token: localStorage.getItem('token')
    }
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
  },
  computed: {
    ...mapState({
      userInfo: "userInfo",
      token: "token",
    }),
    ...mapState("utils", ["loader", "menu","sideBar"]),
  },
  async created() {
    const userToken = this.token || localStorage.getItem('token');
    if (userToken) {
      const userDecode = jwtDecode(userToken);
      this.setUserInfo(userDecode);
    } else {
      this.$router.push("/login").catch((e) => {});
    }
  },

};
</script>

<style scoped>
</style>
